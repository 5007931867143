.balloon-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: balloon-float 4s ease-in-out infinite, balloon-move 4s linear infinite;
}

@keyframes balloon-float {
    0% {
        transform: translate(-50%, -100%);
    }
    50% {
        transform: translate(-50%, -80%);
    }
    100% {
        transform: translate(-50%, -100%);
    }
}


@keyframes balloon-move {
    0% {
        transform:
                translate(-50%, -100%)
                /*rotateX(0) */
                /*rotateY(0) */
                /*rotateZ(-15deg);*/
    }
    50% {
        transform: translate(-50%, -80%)
        /*rotateX(0deg) */
        /*rotateY(0deg) */
        /*rotateZ(0deg);*/
    }
    100% {
        transform: translate(-50%, -100%)
        /*rotateX(0deg) */
        /*rotateY(0deg) */
        /*rotateZ(-15deg);*/
    }
}

@keyframes balloons {
    0%,100%{ transform:translateY(0) rotate(-4deg); }
    50%{ transform:translateY(-25px) rotate(4deg); }
}
